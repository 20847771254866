import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import { withCookies } from 'react-cookie';
import config from './_config';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const AdminLayout = React.lazy(() => import('./containers/AdminLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const QuickAction = React.lazy(() => import('./views/Pages/QuickAction'));
const Cancel = React.lazy(() => import('./views/Pages/Cancel'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {

  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      tokenId : cookies.get('tokenId') || "",
      token : null,
      sessions: null,
      alertMessage: "",
      screenshots : null
    };
    if (this.state.tokenId !== ""){
      this.loadUserTokenData();
    }

    //Bind all functions
    this.loadsessions = this.loadsessions.bind(this);
    this.startsession = this.startsession.bind(this);
    this.deactivatesession = this.deactivatesession.bind(this);
    this.loadUserTokenData = this.loadUserTokenData.bind(this);
    this.loadClientData = this.loadClientData.bind(this);
    this.updateLogin = this.updateLogin.bind(this);
    this.logoutHandler = this.logoutHandler.bind(this);
    this.loadSessionData = this.loadSessionData.bind(this);
  }

  loadsessions(){
    let url = config.api + "/session";
    fetch(url , {
      method: 'GET',
      headers: {
        'Authorization': this.state.tokenId,
      }
    }).then(res => res.json())
      .then((data) => {
        let sess = data.data;
        if (sess){
          this.setState({ sessions : sess });

          //If any session is still starting, update session statuses
          let hasStarting = false;
          sess.map(session => { hasStarting = hasStarting || session.status === "Starting" || session.status === "Booting"; return 1; });
          if (hasStarting){
            setTimeout(this.loadsessions, 2000);
          }
        }
      })
      .catch(console.log);
  }

  startsession(e){
    let url = config.api + "/session";
    e.target.disabled = true;
    window.startButton = e.target; //Becomes global... Oink
    this.setState({ alertMessage: "" })
    
    fetch(url , {
      method: 'POST',
      headers: {
        'Authorization': this.state.tokenId,
      }
    }).then(res => {
        if (res.ok) return res.json();
        else throw res;
        })
      .then((data) => {
        if (data.data){
          window.startButton.disabled = false;
          this.loadsessions();
        }
      })
      .catch((data) => {
        window.startButton.disabled = false;
        data.json().then(data => {
          if (data.message){
            this.setState({ alertMessage : data.message})
          } else {
            this.setState({ alertMessage : "Error: could not start session"});
            console.log(data);
          }
        });
      });
  }

  deactivatesession(sessionId){
    let url = config.api + "/session/" + sessionId + "/deactivate";
    let newSessions = this.state.sessions.map(session => {
      if (session.sessionId === sessionId){
        session.status = "Terminating";
      }
      return session;
    });
    this.setState({ sessions : newSessions });

    fetch(url , {
      method: 'POST',
      headers: {
        'Authorization': this.state.tokenId,
      }
    }).then(res => res.json())
      .then((data) => {
        this.loadsessions();
      })
      .catch(console.log);
  }

  loadUserTokenData(){
    let url = config.api + "/token/" + this.state.tokenId;
    fetch(url , {
      method: 'GET',
      headers: {
        'Authorization': this.state.tokenId,
      }
    }).then(res => res.json())
      .then((data) => {
        let token = data.data;
        if (token) this.setState({ token : token });
        this.loadClientData();
        this.loadClientScreenshots();
      })
      .catch(console.log);
  }

  loadSessionData(sessionId, callback){
    let url = config.api + "/session/" + sessionId + "/status";
    fetch(url , {
      method: 'GET',
      headers: {
        'Authorization': this.state.tokenId,
      }
    }).then(res => res.json())
      .then((data) => {
        let users = data.data;
        if (users)
          callback(users);
      })
      .catch(console.log);
  }

  loadClientData(){
    let url = config.api + "/client/" + this.state.token.clientId;
    fetch(url , {
      method: 'GET',
      headers: {
        'Authorization': this.state.tokenId,
      }
    }).then(res => res.json())
      .then((data) => {
        let client = data.data;
        if (client) this.setState({ client : client })
      })
      .catch(console.log);
  }

  loadClientScreenshots(){
    let url = config.api + "/client/" + this.state.token.clientId + "/snapshots";
    fetch(url , {
      method: 'GET',
      headers: {
        'Authorization': this.state.tokenId,
      }
    }).then(res => res.json())
      .then((data) => {
        let screenshots = data.data;
        if (screenshots) this.setState({ screenshots : screenshots })
      })
      .catch(console.log);
  }

  updateLogin(token){
    const { cookies } = this.props;
    this.setState({
      tokenId : token.tokenId
    });
    cookies.set('tokenId', token.tokenId, { path: '/' });
    window.location.href = "/dashboard";
  }

  logoutHandler(){
    const { cookies } = this.props;
    cookies.set('tokenId', "", { path: '/' });
    window.location.href = "/login";
  }

  render() {
    return (
      <BrowserRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/cancel" name="Cancel session" render={props => <Cancel {...props}/>} />
              <Route exact path="/quick" name="Quick Action Page" render={props => <QuickAction {...props}/>} />
              <Route exact path="/login" name="Login Page" render={props => <Login {...props} loginCallback={this.updateLogin}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/" name="Home" render={props => <AdminLayout 
                {...props} 
                appstate={this.state} 
                loadsessions={this.loadsessions} 
                startsession={this.startsession} 
                deactivatesession={this.deactivatesession}
                loadsessiondata={this.loadSessionData}
                logout={this.logoutHandler}/>} />
            </Switch>
          </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default withCookies(App);
